import { Match, Router } from "./router";
import { COLLECTION, HOME, STDIO } from "./routes";
import { Header } from "./header";
import { lazy, Suspense } from "react";

const LazyHome = lazy(() =>
  import("./pages/home").then((module) => ({ default: module.Home }))
);

const LazyCollection = lazy(() =>
  import("./pages/collection").then((module) => ({
    default: module.Collection,
  }))
);

const LazyStdio = lazy(() =>
  import("./pages/stdio").then((module) => ({
    default: module.Stdio,
  }))
);

export function Main() {
  return (
    <Router>
      <Match route={HOME}>
        <Suspense>
          <Header />
          <LazyHome />
        </Suspense>
      </Match>
      <Match route={COLLECTION}>
        {({ params }) => (
          <Suspense>
            <Header />
            <LazyCollection collectionName={params.collectionName} />
          </Suspense>
        )}
      </Match>
      <Match route={STDIO}>
        <Suspense>
          <LazyStdio />
        </Suspense>
      </Match>
    </Router>
  );
}

import { matchParam, Param } from "./param";
import { toUrlObject } from "./to-url-object";

type Part = string | Param;

export type Params<T> = T extends {} ? T : {};

export type ParamsOfRoute<TRoute extends Route<any>> = TRoute extends Route<
  infer T
>
  ? T
  : never;

export class Route<T = unknown> {
  #pathParts: Part[];
  #queryParts: Record<string, Param>;

  constructor(pathParts: Part[], queryParams: Record<string, Param>) {
    this.#pathParts = pathParts;
    this.#queryParts = queryParams;
  }

  path(part: string): Route<T>;
  path<K extends string, V extends string, R extends boolean>(
    part: Param<K, V, R>
  ): Route<Params<T> & (R extends true ? { [k in K]: V } : { [k in K]?: V })>;
  path<K extends string, V extends string, R extends boolean>(
    part: string | Param<K, V, R>
  ): Route<unknown> {
    if (typeof part === "string") {
      const staticPathParts: Part[] = part
        .toLowerCase()
        .split("/")
        .filter(Boolean);
      return new Route(
        this.#pathParts.concat(staticPathParts),
        this.#queryParts
      );
    }

    return new Route(this.#pathParts.concat([part]), this.#queryParts);
  }

  query<K extends string, V extends string, R extends boolean>(
    part: Param<K, V, R>
  ): Route<Params<T> & (R extends true ? { [k in K]: V } : { [k in K]?: V })> {
    return new Route(this.#pathParts, {
      ...this.#queryParts,
      [part._name]: part,
    });
  }

  toHref(params: T): string {
    // Pathname
    let pathname = "";
    for (const part of this.#pathParts) {
      if (typeof part === "string") {
        pathname += "/" + encodeURIComponent(part);
      } else {
        const value = params[part._name as keyof T];
        if (value) {
          pathname += "/" + encodeURIComponent(value as unknown as string);
        }
      }
    }
    if (pathname === "") {
      pathname = "/";
    }

    // Query
    let query = "";
    for (const queryParam of Object.keys(this.#queryParts)) {
      const part = this.#queryParts[queryParam];
      const value = params[part._name as keyof T];
      if (value) {
        query +=
          (query ? "&" : "?") +
          encodeURIComponent(queryParam) +
          "=" +
          encodeURIComponent(value as unknown as string);
      }
    }

    return pathname + query;
  }

  match(href: URL | string): Params<T> | null {
    const url = toUrlObject(href);
    if (url.origin !== window.location.origin) {
      return null;
    }

    // Pathname
    const subPaths = url.pathname.split("/").filter(Boolean);
    const pathMatch = matchPath(this.#pathParts, subPaths);
    if (!pathMatch) {
      return null;
    }

    // Query
    const queryMatch: Record<string, string> = {};
    for (const [queryParam, part] of Object.entries(this.#queryParts)) {
      const rawValue = url.searchParams.get(queryParam);
      const value = rawValue == null ? undefined : matchParam(part, rawValue);
      if (value == null) {
        if (part._required) {
          return null;
        }
      } else {
        queryMatch[part._name] = value;
      }
    }

    return { ...pathMatch, ...queryMatch } as Params<T>;
  }

  /**
   * String representation of the route, used for debugging.
   */
  toString() {
    let s = "/" + this.#pathParts.map((part) => part.toString()).join("/");
    const querystring = Object.entries(this.#queryParts)
      .map(([name, param]) => {
        return `${name}=${param.toString()}`;
      })
      .join("&");
    if (querystring) {
      s += "?" + querystring;
    }
    return s;
  }
}

export function route(path: string = ""): Route<void> {
  return new Route<void>([], {}).path(path);
}

function matchPath(parts: Part[], path: string[]): {} | null {
  const params: Record<string, string> = {};
  let iPart = 0;
  let iPath = 0;
  for (; iPart < parts.length && iPath < path.length; iPart++, iPath++) {
    const part = parts[iPart];
    const subPath = path[iPath];

    if (typeof part === "string") {
      if (part === subPath.toLowerCase()) {
        continue;
      } else {
        return null;
      }
    } else {
      const value = matchParam(part, subPath);
      if (value != null) {
        if (!part._required) {
          // Not a required part, so may have to backtrack
          const lookaheadMatch = matchPath(
            parts.slice(iPart + 1),
            path.slice(iPath + 1)
          );
          if (lookaheadMatch) {
            params[part._name] = decodeURIComponent(subPath);
            Object.assign(params, lookaheadMatch);
          } else {
            // Skip this optional part and repeat current subPath
            iPath--;
          }
        } else {
          params[part._name] = decodeURIComponent(value);
        }
      } else {
        if (part._required) {
          return null;
        } else {
          // Skip this optional part
          continue;
        }
      }
    }
  }

  if (iPart < parts.length) {
    // Check if remaining parts are all optional
    if (
      parts
        .slice(iPart)
        .every((part) => typeof part !== "string" && !part._required)
    ) {
      return params;
    } else {
      return null;
    }
  }

  if (iPath < path.length) {
    return null;
  }

  return params;
}

import {
  createContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useContext,
} from "react";
import { LinkInterceptor } from "./link-interceptor";
import { History } from "./history";
import { Route } from "./route";
import { matchIn } from "./match-in";

export type RouterContextValue = {
  url: URL;
  previousUrl: URL | null;
  transitionId: number;
  push: (href: string) => void;
  replace: (href: string) => void;
  back: () => void;
  match: <T>(route: Route<T>) => (T extends {} ? T : {}) | null;
  matchIn: <T extends { [key: string]: Route<any> }>(
    routes: T
  ) => keyof T | null;
};

const throwNoContext = () => {
  throw new Error("You must put a <Router> at the root of the application.");
};

export const RouterContext = createContext<RouterContextValue>({
  url: new URL(window.location.href),
  previousUrl: null,
  transitionId: 0,
  push: throwNoContext,
  replace: throwNoContext,
  back: throwNoContext,
  match: throwNoContext,
  matchIn: throwNoContext,
});

type Props = {
  children: ReactNode;
};

const history = new History();

export const Router: FC<Props> = ({ children }) => {
  const [state, setState] = useState<{
    url: URL;
    previousUrl: URL | null;
    transitionId: number;
  }>(() => ({
    url: new URL(window.location.href),
    previousUrl: null,
    transitionId: 0,
  }));

  useEffect(() => {
    history.onChange((action) => {
      setState((state) => ({
        url: new URL(window.location.href),
        previousUrl: state.url,
        transitionId:
          action === "replace" ? state.transitionId : state.transitionId + 1,
      }));
    });
  }, []);

  return (
    <RouterContext.Provider
      value={{
        url: state.url,
        previousUrl: state.previousUrl,
        transitionId: state.transitionId,
        push: history.push,
        replace: history.replace,
        back: history.back,
        match: (route) => route.match(state.url),
        matchIn: (routes) => matchIn(routes, state.url),
      }}
    >
      <LinkInterceptor>{children}</LinkInterceptor>
    </RouterContext.Provider>
  );
};

export const useRouter = () => useContext(RouterContext);

import { LogoLink } from "./logo-link";
import { css } from "@emotion/css";
import { STDIO } from "./routes";

const styles = css`
  align-items: center;
  padding: 18px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
`;

const linksStyles = css`
  display: flex;
  font-size: 0.9em;
  font-weight: bold;
  gap: 16px;
`;

export function Header() {
  return (
    <header className={styles}>
      <LogoLink />
      <div className={linksStyles}>
        <a href={STDIO.toHref()}>FX(STDIO)</a>
      </div>
    </header>
  );
}

import { HOME } from "./routes";
import ALTESC from "./altesc.svg";
import { css } from "@emotion/css";

const styles = css`
  line-height: 0;
`;

export function LogoLink() {
  return (
    <a href={HOME.toHref()} className={styles}>
      <img src={ALTESC} alt="ALT+ESC" />
    </a>
  );
}

const DEFAULT_CONDITION = (s: string) => s;

export class Param<
  K extends string = string,
  V extends string = string,
  R extends boolean = boolean
> {
  readonly _name: K;
  readonly _required: R;
  readonly _condition: (s: string) => V;

  constructor(name: K, required: R, condition: (s: any) => V) {
    this._name = name;
    this._required = required;
    this._condition = condition;
  }

  name<K extends string>(name: K) {
    return new Param<K, V, R>(name, this._required, this._condition);
  }

  required<R extends boolean>(required: R) {
    return new Param<K, V, typeof required>(
      this._name,
      required,
      this._condition
    );
  }

  optional() {
    return this.required(false);
  }

  condition<CondV extends V>(cond: (s: V) => CondV) {
    return new Param<K, CondV>(this._name, this._required, (value) => {
      // conditions are additive
      return cond(this._condition(value));
    });
  }

  oneOf<CondV extends V>(options: CondV[]) {
    return this.condition<CondV>((s) => {
      if ((options as string[]).includes(s)) {
        return s as CondV;
      }
      throw new Error(`Value ${s} not in ${options.join(",")}`);
    });
  }

  /**
   * String representation of the param, used for debugging.
   */
  toString() {
    let flags = "";
    if (!this._required) {
      flags += "?";
    }
    if (this._condition !== DEFAULT_CONDITION) {
      flags += "^";
    }
    return `:${this._name}${flags ? `[${flags}]` : ""}`;
  }
}

export function param<K extends string>(name: K) {
  return new Param<K, string, true>(name, true, DEFAULT_CONDITION);
}

export function matchParam<T1 extends string, T2 extends string>(
  param: Param<string, T2, boolean>,
  value: T1
): T2 | undefined {
  try {
    return param._condition(value);
  } catch (e) {
    return;
  }
}

import { Route } from "./route";
import { toUrlObject } from "./to-url-object";

export const matchIn = <T extends { [key: string]: Route<any> }>(
  routes: T,
  urlLike: URL | string
): keyof T | null => {
  const url = toUrlObject(urlLike);
  for (const [key, route] of Object.entries(routes)) {
    const match = route.match(url);
    if (match) {
      return key;
    }
  }
  return null;
};

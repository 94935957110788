import { ReactElement } from "react";
import { Params, Route } from "./route";
import { useRouter } from "./router";

type MatchRenderer<T> = (props: { params: Params<T> }) => ReactElement | null;

export function Match<T>({
  route,
  children,
}: {
  route: Route<T>;
  children?: MatchRenderer<T> | ReactElement | null;
}): ReactElement | null {
  const router = useRouter();
  const params = router.match(route);
  if (params) {
    if (typeof children === "function") {
      return children({ params });
    }
    return children ?? null;
  }
  return null;
}
